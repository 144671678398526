import React from 'react';
import "../styles/PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      {/* Breadcrumb Navigation */}
      <nav className="breadcrumb">
        <a href="/">Home</a> &raquo; <span>Privacy Policy</span>
      </nav>

      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> 5.12.2024</p>
      <p><strong>Last Updated:</strong> 5.12.2024</p>

      <section>
        <h2>1. Introduction</h2>
        <p>
          This document outlines the principles for processing personal data collected through the use of our application. The document has been prepared to ensure compliance with the General Data Protection Regulation (GDPR) and aims to provide users with clear, precise, and transparent information regarding the collection, use, and protection of their personal data.
        </p>
        <p>
          By using our application, users agree to the terms outlined in this document. Refusal to provide consent for data processing will result in the inability to use the application.
        </p>
      </section>

      <section>
        <h2>2. Data Controller Information</h2>
        <p>
          The data controller responsible for processing user data is:
          <br />
          <strong>Patryk Kotłowski</strong>
          <br />
          Contact: <a href="mailto:patryk.kotlowski@dialectoapp.com">patryk.kotlowski@dialectoapp.com</a>
        </p>
        <p>
          For questions related to data protection, users can contact our Data Protection Officer at: <a href="mailto:patryk.kotlowski@dialectoapp.com">patryk.kotlowski@dialectoapp.com</a>.
        </p>
      </section>

      <section>
        <h2>3. Purpose and Scope of Data Collection</h2>
        <p>
          We collect and process personal data strictly for purposes necessary for the application’s functionality, including but not limited to:
        </p>
        <ul>
          <li>User authentication and account creation.</li>
          <li>Providing core application features, including personalized interactions and enhanced functionality based on user preferences.</li>
          <li>Ensuring compliance with legal obligations.</li>
          <li>Excessive or unnecessary data collection will be avoided.</li>
        </ul>
      </section>

      <section>
        <h2>4. Types of Data Collected</h2>
        <p>The application may collect the following types of personal data:</p>
        <ul>
          <li><strong>Identification Data:</strong> Name, email address, phone number.</li>
          <li><strong>Device Data:</strong> IP address, device type, operating system.</li>
          <li><strong>Application Usage Data:</strong> Time, location, and manner of app use.</li>
          <li><strong>Optional Permissions:</strong> Access to camera, microphone, contacts, location, biometric data (only upon explicit user consent).</li>
          <li>
            <strong>Hobbies and Interests:</strong> Users may voluntarily provide information about their hobbies, interests, or other preferences.
            This data will only be used to provide core functionalities, such as enabling personalized chats about user interests with AI or other relevant features within the application.
          </li>
        </ul>
      </section>

      <section>
        <h2>5. Legal Basis for Data Processing</h2>
        <p>The application processes personal data based on the following legal grounds:</p>
        <ul>
          <li>User consent, which is specific, informed, and unambiguous.</li>
          <li>Compliance with legal obligations.</li>
          <li>Legitimate interests, such as app security or functionality improvements.</li>
        </ul>
      </section>

      <section>
        <h2>6. User Consent</h2>
        <p>
          Users must provide explicit consent for data collection and processing before using the application. Refusal to consent will result in restricted access to the app.
        </p>
      </section>

      <section>
        <h2>7. Data Security</h2>
        <p>We implement robust digital safeguards to protect user data, including:</p>
        <ul>
          <li>Encryption of sensitive data.</li>
          <li>Secure servers with access controls.</li>
          <li>In the event of a data breach, users will be notified as required by applicable laws.</li>
        </ul>
      </section>

      <section>
        <h2>8. Data Retention</h2>
        <p>
          User data will only be stored as long as necessary for the application’s functionality or as required by law. Data no longer required will be securely deleted. Retention periods may be implemented to automate data deletion.
        </p>
      </section>

      <section>
        <h2>9. Sharing Personal Data with Third Parties</h2>
        <p>User data may be shared with the following entities:</p>
        <ul>
          <li><strong>Partner Companies:</strong> Providing additional services or functionality.</li>
          <li><strong>Service Providers:</strong> For hosting, analytics, or customer support.</li>
          <li><strong>Authorities:</strong> If required by law or court order.</li>
        </ul>
        <p>
          Any sharing with third parties will be governed by data protection agreements ensuring compliance with GDPR standards.
        </p>
      </section>

      <section>
        <h2>10. User Rights</h2>
        <p>Users have the following rights under GDPR:</p>
        <ul>
          <li><strong>Access:</strong> Request access to their personal data.</li>
          <li><strong>Correction:</strong> Rectify inaccurate or incomplete data.</li>
          <li><strong>Erasure:</strong> Request deletion of data when no longer needed.</li>
          <li><strong>Restriction:</strong> Limit processing under certain conditions.</li>
          <li><strong>Portability:</strong> Receive their data in a structured, machine-readable format.</li>
          <li><strong>Objection:</strong> Object to certain types of processing, such as marketing.</li>
        </ul>
        <p>To exercise these rights, contact [Insert Contact Information].</p>
      </section>

      <section>
        <h2>11. Third-Party Services and Integrations</h2>
        <p>
          If the application integrates with third-party services (e.g., social media platforms), users will be informed that their data may be processed for purposes such as advertising or platform development.
        </p>
      </section>

      <section>
        <h2>12. Access Permissions</h2>
        <p>The application may request access to certain device functions, such as:</p>
        <ul>
          <li>Camera</li>
          <li>Microphone</li>
          <li>Location</li>
          <li>Contacts</li>
          <li>Biometric Sensors</li>
        </ul>
        <p>
          Permissions will be explicitly requested, and users can decline or modify access settings at any time.
        </p>
      </section>

      <section>
        <h2>13. Hobbies and Interests Data</h2>
        <p>
          To enhance the application’s core functionality, we may collect user-provided information about hobbies, interests, or other preferences. This data will only be used for essential features, such as:
        </p>
        <ul>
          <li>Personalized AI chats tailored to user interests.</li>
          <li>Enhancements to user engagement and interaction within the application.</li>
        </ul>
        <p>
          This data will not be used for marketing purposes or shared with third parties without explicit user consent.
        </p>
      </section>

      <section>
        <h2>14. Record-Keeping and Compliance</h2>
        <p>
          The developer will maintain detailed records of data collection and processing activities, including logs of user interactions with the application.
        </p>
      </section>

      <section>
        <h2>15. User Responsibility to Review Changes</h2>
        <p>
          Users are responsible for regularly reviewing this privacy policy for updates. The modification date provided at the top of this document indicates the most recent changes. Continued use of the application after updates signifies acceptance of the modified terms.
        </p>
      </section>

      <section>
        <h2>16. Contact Information</h2>
        <p>
          For inquiries or complaints regarding this privacy policy, please contact us at: <a href="mailto:support@dialectoapp.com">support@dialectoapp.com</a>
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
