import React, { useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import "./styles/App.css";
import logo from "./assets/logo-white-cropped.jpg";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <div className="app">
      {/* Header */}
      <header className="header">
        <div className="header-left">
          <Link to="/" className="logo-link" onClick={() => setMenuOpen(false)}>
            <img src={logo} alt="Dialecto Logo" className="logo" />
          </Link>
          <nav className="desktop-nav">
            <ul className="nav-links">
              <li>
                <Link to="/" onClick={() => setMenuOpen(false)}>
                  Home
                </Link>
              </li>
              <li>
                <a href="#features" onClick={() => setMenuOpen(false)}>
                  Features
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setMenuOpen(false)}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="header-right">
          <div className="burger" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav className={`mobile-nav ${menuOpen ? "active" : ""}`}>
            <ul className="nav-links">
              <li>
                <Link to="/" onClick={() => setMenuOpen(false)}>
                  Home
                </Link>
              </li>
              <li>
                <a href="#features" onClick={() => setMenuOpen(false)}>
                  Features
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setMenuOpen(false)}>
                  Contact
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Main Content */}
      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
        </Routes>
      </main>

      {/* Footer */}
      <footer className="footer">
        <p>Dialecto</p>
        <p>
          <Link to="/privacy">Privacy Policy</Link>|
          <a href="/terms">Terms and Conditions</a>
        </p>
      </footer>
    </div>
  );
}

export default App;
