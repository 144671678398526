import React from "react";
import "../styles/Home.css";

function Home() {
  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero-section">
        <div className="hero-content">
          <h1>Accelerate Your Language Learning with AI-Powered Methods</h1>
          <p>
            Unlock the world with Dialecto, the app that revolutionizes language
            learning by combining cutting-edge AI technology with proven teaching
            methods.
          </p>
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <div className="feature-card">
          <h2>Personalized For You</h2>
          <p>
            Our AI adapts to your learning style, speed, and goals, ensuring a
            tailored experience every step of the way.
          </p>
        </div>
        <div className="feature-card">
          <h2>Effective &amp; Fun</h2>
          <p>
            Dive into interactive lessons, practice sessions, and real-life
            scenarios. Get feedback in real-time, track your progress, and have
            fun while learning.
          </p>
        </div>
        <div className="feature-card">
          <h2>Achieve Your Goals</h2>
          <p>
            Whether it’s for travel, career growth, or personal development,
            Dialecto equips you with the skills to speak confidently and
            effectively.
          </p>
        </div>
      </section>

      {/* Immersive Conversations */}
      <section className="content-card immersive-conversations">
        <h2>Immersive Conversations</h2>
        <p>
          Step into real-life scenarios with AI-powered dialogues that help you
          build confidence and fluency naturally. These adaptive conversations
          simulate authentic interactions, making your learning experience both
          intuitive and engaging!
        </p>
        <p>
          Discover how our AI-Powered Immersive Conversations transform language
          learning into a seamless and enjoyable journey.
        </p>
        {/* <img
          src="https://via.placeholder.com/800x400?text=Immersive+Conversations+Screenshot"
          alt="Immersive Conversations"
          className="screenshot"
        /> */}
      </section>

      {/* Role-Playing Scenarios */}
      <section className="content-card role-playing">
        <h2>Role-Playing Scenarios</h2>
        <p>
          Achieve more realistic and personal connections in realistic,
          AI-enhanced role scenarios. Whether you’re ordering at a café, leading
          a business meeting, or handling a unique situation, Dialecto’s
          role-playing scenarios are tailored to your personal goals.
        </p>
        <p>
          Discover how our AI Role-Playing Scenarios and customizable roles can
          transform the way you practice and perfect your speaking skills!
        </p>
        {/* <img
          src="https://via.placeholder.com/800x400?text=Role-Playing+Scenarios+Screenshot"
          alt="Role-Playing Scenarios"
          className="screenshot"
        /> */}
      </section>

      {/* Flashcard Challenges */}
      <section className="content-card flashcards">
        <h2>Flashcard Challenges</h2>
        <p>
          Boost your vocabulary with a personalized glossary and our
          AI-optimized flashcard system. Using the proven Leitner System,
          Dialecto presents new words at the perfect intervals, helping you
          recall them when it matters most.
        </p>
        <p>
          Explore how the Leitner System and AI can supercharge your language
          retention!
        </p>
        {/* <img
          src="https://via.placeholder.com/800x400?text=Flashcard+Challenges+Screenshot"
          alt="Flashcard Challenges"
          className="screenshot"
        /> */}
      </section>

      {/* Teacher Method */}
      <section className="content-card teacher-method">
        <h2>Teacher Method</h2>
        <p>
          Turn the tables and become the teacher! With this unique feature,
          you’ll correct simulated mistakes made by our AI chatbot, reinforcing
          your understanding through the power of explanation. Then, see the
          correct answers and discover how the AI-Enhanced Teacher Method takes
          your learning to the next level!
        </p>
        {/* <img
          src="https://via.placeholder.com/800x400?text=Teacher+Method+Screenshot"
          alt="Teacher Method"
          className="screenshot"
        /> */}
      </section>

      {/* Contact Us */}
      <section className="content-card contact-us">
        <h2>Contact Us</h2>
        <p>
          Have questions? Reach out to us at{" "}
          <a href="mailto:support@dialectoapp.com">support@dialectoapp.com</a>
        </p>
        <p>
          Or visit our <a href="#faq">FAQ page</a> for more information.
        </p>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2>Experience Dialecto Today</h2>
        <p>
          Enhance your language journey with interactive and engaging methods
          tailored to your style.
        </p>
        <button className="cta-button">Download on the App Store</button>
      </section>
    </div>
  );
}

export default Home;
