import React from 'react';
import "../styles/TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <nav className="breadcrumb">
        <a href="/">Home</a> &raquo; <span>TERMS AND CONDITIONS</span>
      </nav>
      <h1>TERMS AND CONDITIONS</h1>
      <p className="dates">
        <strong>Effective Date:</strong> December 5, 2024<br />
        <strong>Last Updated:</strong> December 5, 2024
      </p>
      <section className="section">
        <h2>1. Service Provider Information</h2>
        <p>
          The application is provided by:<br />
          <strong>Company Name:</strong> Patryk Kotłowski<br />
          <strong>Contact Information:</strong>{" "}
          <a href="mailto:patryk.kotlowski@dialectoapp.com">
            patryk.kotlowski@dialectoapp.com
          </a>
        </p>
      </section>
      <section className="section">
        <h2>2. Scope of Services</h2>
        <p>
          The application offers services designed to support users in learning
          languages or other educational topics using AI-based tools. Its
          functionalities include:
        </p>
        <ul>
          <li>
            AI-powered interactions tailored for language practice and
            educational purposes.
          </li>
          <li>
            Personalized content generation based on user preferences and
            progress.
          </li>
        </ul>
        <p>
          While the application strives to enhance learning, it should not be
          considered a substitute for professional tutoring or certified
          educational programs.
        </p>
      </section>
      <section className="section">
        <h2>3. Liability for AI-Generated Content</h2>
        <p>
          The company is not responsible for the accuracy, completeness, or
          potential consequences of using AI-generated content. Users acknowledge
          that AI-generated content may contain errors or inconsistencies. Every
          effort is made to ensure the AI-generated content meets the highest
          quality and safety standards, but the company cannot guarantee complete
          accuracy, reliability, or suitability for specific learning objectives.
          By using the application, users accept full responsibility for their
          reliance on and usage of AI-generated content.
        </p>
      </section>
      <section className="section">
        <h2>4. Age Restrictions</h2>
        <p>
          The application is intended for users aged 18 years or older. Individuals
          under the age of 18 are strictly prohibited from using the application.
          By creating an account or using the application, users confirm that they
          are at least 18 years old.
        </p>
      </section>
      <section className="section">
        <h2>5. User Responsibilities</h2>
        <p>
          Users agree to use the application and its AI-generated content
          responsibly and only for lawful purposes. The application must not be
          used to engage in illegal, harmful, or unethical activities, including
          the dissemination of inappropriate content. Users are solely
          responsible for any actions or consequences resulting from their use of
          AI-generated content or interactions within the application.
        </p>
      </section>
      <section className="section">
        <h2>6. Payments</h2>
        <p>
          The application may offer in-app purchases or subscription-based services
          to access additional features or content. Accepted payment methods include
          Apple Pay and Google Pay. Payment details and applicable fees, including
          billing cycles, are provided at the time of purchase. It is the user’s
          responsibility to ensure that their payment information is accurate and
          up to date. If a user deactivates their subscription, the service will
          remain available until the end of the current payment period. No refunds
          will be issued for any unused portion of that period. All purchases are
          subject to applicable taxes and transaction fees as required by law.
        </p>
      </section>
      <section className="section">
        <h2>7. Refund Policy</h2>
        <p>
          Refunds for in-app purchases or subscriptions are handled as follows:
        </p>
        <ul>
          <li>
            Refund requests must be submitted within [Insert Refund Period, e.g.,
            14 days] of the purchase date.
          </li>
          <li>
            Refunds will only be issued if the purchased feature or service is
            proven to be defective or unavailable due to the company’s fault, and
            the user has not consumed or accessed significant portions of the
            purchased content or features.
          </li>
          <li>
            Refund requests can be made through [Insert Communication Channel,
            e.g., email, in-app support]. Refunds will be processed within [Insert
            Processing Time, e.g., 10 business days] upon approval. Requests that
            do not meet the above criteria will be denied.
          </li>
        </ul>
      </section>
      <section className="section">
        <h2>8. Data Protection and Privacy</h2>
        <p>
          User data is collected, processed, and protected in compliance with
          applicable data protection laws, including the General Data Protection
          Regulation (GDPR) and the California Consumer Privacy Act (CCPA). For
          detailed information about data collection and processing, please review
          our Privacy Policy. Users have rights regarding their data, including
          access, correction, deletion, and portability. To exercise these rights,
          users can contact us at [Insert Contact Email].
        </p>
      </section>
      <section className="section">
        <h2>9. Complaint Procedures</h2>
        <p>
          Complaints, including issues with payments or features, can be submitted
          through{" "}
          <a href="mailto:support@dialectoapp.com">support@dialectoapp.com</a>.
          We will respond to complaints within 10 business days of receipt. If users
          are not satisfied with the resolution, they may escalate the complaint
          using applicable legal avenues (see Section 11).
        </p>
      </section>
      <section className="section">
        <h2>10. Amendments to Terms</h2>
        <p>
          These terms and conditions may be updated periodically. Users are
          responsible for reviewing the latest version of the terms, indicated by
          the “Last Updated” date at the top of this document. Continued use of the
          application after an update constitutes acceptance of the revised terms.
        </p>
      </section>
      <section className="section">
        <h2>11. Governing Law and Jurisdiction</h2>
        <p>
          These terms are governed by the laws of the Republic of Poland. Any
          disputes arising from these terms or the use of the application will be
          resolved in the courts of Poland. Alternative dispute resolution
          mechanisms, such as mediation or arbitration, may be used where
          applicable, based on mutual agreement.
        </p>
      </section>
      <section className="section">
        <h2>12. Purpose of AI-Generated Content</h2>
        <p>
          The AI-generated content provided by the application is intended for
          informational, educational, and entertainment purposes. It is not
          professional advice or a certified learning tool. Users should verify the
          accuracy and relevance of the content for their specific needs. By using
          this application, you confirm that you have read, understood, and agree to
          these terms and conditions. For inquiries or complaints, please contact us
          at{" "}
          <a href="mailto:support@dialectoapp.com">support@dialectoapp.com</a>.
        </p>
      </section>
    </div>
  );
};

export default TermsAndConditions;
